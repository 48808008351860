import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/login/login.vue"

const routes = [
  {
    path: "/",
    // redirect: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    // beforeEnter: () => {
    //   // console.log(to,from)
    //   alert("路由守卫");
    // }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) =>{
  if (to.fullPath === '/home'){
    if(!sessionStorage.getItem('token')) {
      next('/')
    } else {
      next()
    }
  } else {
    next()
  }

  // if(to.fullPath === '/home' ){

  //   var c_start = document.cookie.indexOf("token=")

  //   if(document.cookie.length > 0 && c_start != -1) {
  //     next()
  //   } else {
  //     next('/')
  //   }

  // } else {
  //   next()
  // }

})

export default router;
