import moudle1TopImg from '@/assets/home/moudle_1_top_img.png';
import computerBox from '@/assets/home/computer_box.png';

import finger from '@/assets/home/finger.png'

// 插画图导入
// import balls from '@/assets/paster/balls.png'
//
// import bottomGroup from '@/assets/paster/bottom_group.png'
// import bottomGroup1 from '@/assets/paster/bottom_group1.png'
// import bottomGroup3 from '@/assets/paster/bottom_group3.png'
//
// import boxes from '@/assets/paster/boxes.png'
// import boxes1 from '@/assets/paster/boxes1.png'
// import broadcast from '@/assets/paster/broadcast.png'
//
// import car from '@/assets/paster/car.png'
// import car1 from '@/assets/paster/cars1.png'
// import cloud from '@/assets/paster/cloud.png'
// import cloud1 from '@/assets/paster/cloud1.png'
// import computer from '@/assets/paster/computer.png'
//
// import creame from '@/assets/paster/creame.png'
// import eleCloud from '@/assets/paster/eleCloud.png'
// import Frame from '@/assets/paster/Frame.png'
// import houses from '@/assets/paster/houses.png'
// import houses1 from '@/assets/paster/houses1.png'
// import houses2 from '@/assets/paster/houses2.png'
// import houses3 from '@/assets/paster/houses3.png'
//
// import mindMap from '@/assets/paster/mind-map.png'
// import mind from '@/assets/paster/mind.png'
// import phone from '@/assets/paster/phone1.png'
// import search from '@/assets/paster/search.png'
//
// import steel from '@/assets/home/steel.png'

// import arrowDown from '@/assets/moudleArrow/arrowDown'
// import arrowUp from '@/assets/moudleArrow/arrow_up.png'
// import arrowLeft from '@/assets/moudleArrow/arrow_left.png'
import arrowRight from '@/assets/moudleArrow/arrow_right.png'

import green_arrow from '@/assets/moudleArrow/yellow.png'
import Frame from '@/assets/moudle1/Frame.png'
import Group36625 from '@/assets/moudle1/Group_36625.png'
import Group from '@/assets/moudle1/Group.png'
import undrawMoments from '@/assets/moudle1/undraw_moments_0y20.png'
import Group36631 from '@/assets/moudle1/Group_36631.png'
import Group36635 from '@/assets/moudle1/Group_36635.png'
import Group36643 from '@/assets/moudle1/Group_36643.png'
import freepikLightBulbInject88 from '@/assets/moudle1/freepik--light-bulb--inject-88.png'
import Group2 from '@/assets/moudle1/Group2.png'
import illustration1 from '@/assets/moudle1/illustration1.png'
import illustration2 from '@/assets/moudle1/illustration2.png'
// import di from '@/assets/moudle1/bgi.png'
import steel from '@/assets/home/steel.png'
import one from '@/assets/moudle1/one.png'
export default [{
    type: 'img', // 图片类型
    src: moudle1TopImg,
    style: {
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "556px",
        height: "222px",
    }
},

{
    type: 'img', // 图片类型
    src: Frame,
    style: {
        position: "absolute",
        top: "347px",
        left: "229px",
        width: "63px",
        height: "60px",
    }
},

{
    type: 'img', // 图片类型
    src: one,
    style: {
        position: "absolute",
        top: "421px",
        left: "256px",
        width: "13px",
        height: "129px",
    }
},

{
    type: 'img', // 图片类型
    src: steel,
    style: {
        position: "absolute",
        top: "594px",
        left: "376px",
        width: "45px",
        height: "90px",
    }
},

{
    type: 'img', // 图片类型
    src: steel,
    style: {
        position: "absolute",
        top: "586px",
        left: "958px",
        width: "45px",
        height: "90px",
    }
},

{
    type: 'img', // 图片类型
    src: Group36625,
    style: {
        position: "absolute",
        top: "604px",
        left: "117px",
        width: "234px",
        height: "89px",
        zIndex:-5
    }
},

{
    type: 'img', // 图片类型
    src: Group,
    style: {
        position: "absolute",
        top: "89px",
        left: "685px",
        width: "330px",
        height: "241px",
        zIndex: 103,
    }
},

{
    type: 'img', // 图片类型
    src: undrawMoments,
    style: {
        position: "absolute",
        top: "584px",
        left: "706px",
        width: "128px",
        height: "131px",
    }
},

{
    type: 'img', // 图片类型
    src: Group36631,
    style: {
        position: "absolute",
        top: "622px",
        left: "1506px",
        width: "71px",
        height: "122px",
    }
},

{
    type: 'img', // 图片类型
    src: Group36635,
    style: {
        position: "absolute",
        top: "63px",
        left: "1336px",
        width: "107px",
        height: "182px",
        zIndex: 103,
    }
},

{
    type: 'img', // 图片类型
    src: Group36643,
    style: {
        position: "absolute",
        top: "321px",
        left: "1110px",
        width: "144px",
        height: "224px",
    }
},

{
    type: 'img', // 图片类型
    src: freepikLightBulbInject88,
    style: {
        position: "absolute",
        top: "297px",
        left: "1580px",
        width: "81px",
        height: "92px",
    }
},

{
    type: 'img', // 图片类型
    src: Group2,
    style: {
        position: "absolute",
        top: "579px",
        left: "1480px",
        width: "37px",
        height: "37px",
    }
},

{
    type: 'img', // 图片类型
    src: illustration2,
    style: {
        position: "absolute",
        top: "662px",
        left: "360px",
        width: "238px",
        height: "114px",
        zIndex: 5,
    }
},

{
    type: 'img', // 图片类型
    src: illustration1,
    style: {
        position: "absolute",
        top: "624px",
        left: "20px",
        width: "1971px",
        height: "153px",
    }
},

// {
//     type: 'img', // 图片类型
//     src: di,
//     style: {
//         position: "absolute",
//         top: "-141px",
//         left: "-111px",
//         width: "1909px",
//         height: "900px",
//         zIndex:-20
//     }
// },

    {
        type: 'computerBox',
        text: {
            info: "初识短视频",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "14px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "310px",
            left: "22px"
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "短视频内容创作要素",
            style: {
                fontSize: "18px",
                fontWeight: "600",
                paddingBottom: "22px",
                width: "100px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "408px",
            left: "442px"
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "短视频制作原则",
            style: {
                fontSize: "18px",
                fontWeight: "600",
                paddingBottom: "22px",
                width: "75px",
                textAlign:"center",
                textIndent:"4px",
                overflow:"hidden",
                // zIndex:105
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "675px",
            left: "984px"
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "团队组建",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "14px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "121px",
            left: "1134px"
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "脚本准备",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "14px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "420px",
            left: "1233px"
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "短视频内容核心创意",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "21px",
                paddingRight: "5px",
                marginLeft: "6px",
                // zIndex:105
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "673px",
            left: "1688px"
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "短视频的创意类型",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "14px",
                width:"100px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "285px",
            left: "1688px"
        }
    },
    {
        type: 'tip', // 黄色的标语卡片类型
        text: 'Part1',
        style: {
            width:"42px",
            height:"15px",
            position: "absolute",
            top: "252px",
            left: "14px",
            background: "#FAFF6A",
            paddingLeft: "49px",
            paddingRight: "49px"
        }
    },
    {
        type: 'tipFinger',
        src: finger,
        style: {
            position: "absolute",
            top: "269px",
            left: "122px"
        },
    },
    {
        type: 'tip', // 标语卡片类型
        text: '结束第1阶段学习',
        style: {
            position: "absolute",
            top: "159px",
            right: "183px",
            color: "#000",
            background: "#707070",
        }
    },
    {
        type: 'menuTip', // 菜单卡片 带有交互的卡片
        text: '什么是短视频',
        sync: 'A-1',
        style: {
            position: "absolute",
            bottom: "284px",
            left: "116px",
            // zIndex: 102,
            color: "#FFFFFF !important",
            width: "109px",
            height: "32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: []
    },
    {
        type: 'menuTip',
        text: "短视频交流平台",
        sync: 'A-2',
        style: {
            position: "absolute",
            left: "116px",
            bottom: "153px",
            // zIndex: 101,
            color: "#FFFFFF !important",
            width: "141px",
            height: "32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "短视频的优势",
        sync: 'A-3',
        style: {
            position: "absolute",
            left: "330px",
            bottom: "153px",
            color: "#FFFFFF !important",
            width: "109px",
            height: "32px",
            fontSize:"16px",
            lineHeight:"31px"

        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "标题",
        sync: 'A-4',
        style: {
            position: "absolute",
            left: "333px",
            bottom: "380px",
            // zIndex: 103,
            color: "#FFFFFF !important",
            width:"99px",
            height:"32px",
            fontSize: "16px",
            lineHeight: "30px"

        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "画面",
        sync: 'A-5',
        style: {
            position: "absolute",
            left: "664px",
            top: "327px",
            color: "#FFFFFF !important",
            width:"99px",
            height:"32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "价值或和趣味性",
        sync: 'A-6',
        style: {
            position: "absolute",
            left: "664px",
            top: "424px",
            // zIndex: 101,
            color: "#FFFFFF !important",
            height: "32px",
            width: "136px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "音乐",
        sync: 'A-7',
        style: {
            position: "absolute",
            left: "664px",
            top: "517px",
            // zIndex: "101",
            color: "#FFFFFF !important",
            width:"99px",
            height:"32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "基础三原则",
        sync: 'A-8',
        style: {
            position: "absolute",
            bottom: "124px",
            left: "914px",
            // zIndex: 103,
            color: "#FFFFFF !important",
            width:"99px",
            height:"32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "拍摄工具的选择",
        sync: 'A-9',
        style: {
            position: "absolute",
            bottom: "208px",
            left: "878px",
            // zIndex: 103,
            color: "#FFFFFF !important",
            width: "136px",
            height:" 32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "拍摄注意事项",
        sync: 'A-10',
        style: {
            position: "absolute",
            bottom: "294px",
            left: "903px",
            // zIndex: 100,
            color: "#FFFFFF !important",
            width: "112px",
            height: "32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "剪辑的基本原则",
        sync: 'A-11',
        style: {
            position: "absolute",
            bottom: "379px",
            left: "879px",
            color: "#FFFFFF !important",
            width: "136px",
            height: "32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "核心人员",
        sync: 'A-12',
        style: {
            position: "absolute",
            left: "1324px",
            top: "245px",
            color: "#FFFFFF !important",
            width: "105px",
            height: "32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "辅助人员",
        sync: 'A-13',
        style: {
            position: "absolute",
            left: "1324px",
            top: "332px",
            color: "#FFFFFF !important",
            width: "105px",
            height: "32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "脚本的3种形式",
        sync: 'A-14',
        style: {
            position: "absolute",
            left: "1324px",
            bottom: "164px",
            // zIndex: 103,
            color: "#FFFFFF !important",
            width: "136px",
            height: "32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "按照大纲安排素材",
        sync: 'A-15',
        style: {
            position: "absolute",
            left: "1324px",
            bottom: "82px",
            // zIndex: "101",
            color: "#FFFFFF !important",
            width: "141px",
            height: "32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "什么是创意",
        sync: 'A-16',
        style: {
            position: "absolute",
            left: "1577px",
            bottom: "103px",
            // zIndex: "101",
            color: "#FFFFFF !important",
            width: "105px",
            height: "32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "创意生产的基础",
        sync: 'A-17',
        style: {
            position: "absolute",
            left: "1545px",
            bottom: "186px",
            // zIndex: 101,
            color: "#FFFFFF !important",
            width: "138px",
            height: "32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "创意与创作的关系",
        sync: 'A-18',
        style: {
            position: "absolute",
            left: "1543px",
            bottom: "273px",
            // zIndex: 101,
            color: "#FFFFFF !important",
            width: "141px",
            height: "32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        type: 'menuTip',
        text: "短视频创意类型",
        sync: 'A-19',
        style: {
            position: "absolute",
            left: "1545px",
            bottom: "473px",
            // zIndex: 101,
            color: "#FFFFFF !important",
            width: "138px",
            height: "32px",
            fontSize: "16px",
            lineHeight: "30px"
        },
        menusList: [],
        menusCurrIndex: 0
    },
    {
        id: '1',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "430px",
            left: "60px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '2',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "563px",
            left: "60px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '3',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "562px",
            left: "473px",
            transform: 'rotateY(180deg)',
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '4',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "334px",
            left: "473px",
            transform: "rotateY(180deg)",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '5',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "338px",
            left: "610px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '6',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "435px",
            left: "610px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '7',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "530px",
            left: "610px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '8',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            bottom: "124px",
            left: "1057px",
            transform: 'rotateY(180deg)',
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '9',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            bottom: "211px",
            left: "1058px",
            transform: 'rotateY(180deg)',
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '10',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            bottom: "297px",
            left: "1058px",
            transform: 'rotateY(180deg)',
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '11',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            bottom: "382px",
            left: "1058px",
            transform: 'rotateY(180deg)',
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '12',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "257px",
            left: "1270px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '13',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "342px",
            left: "1270px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '14',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "551px",
            left: "1270px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '15',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "637px",
            left: "1270px",
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '16',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "612px",
            left: "1723px",
            transform: 'rotateY(180deg)',
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '17',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "528px",
            left: "1723px",
            transform: 'rotateY(180deg)',
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '18',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "442px",
            left: "1723px",
            transform: 'rotateY(180deg)',
            background: `url(${green_arrow}) center no-repeat`
        }
    },
    {
        id: '19',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "230px",
            left: "1723px",
            transform: 'rotateY(180deg)',
            background: `url(${green_arrow}) center no-repeat`
        }
    }
];
