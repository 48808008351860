<template>
  <transition name="cmp_toast">
    <div class="_base_toast" v-if="visible">
      <div class="_mask_layer">{{ msg }}</div>
    </div>
  </transition>
</template>

<script>
let timer = null;
export default {
  name: "Toast",
  data() {
    return {
      visible: false,
      msg: "系统异常",
    }
  },
  methods: {
    show(msg, millisecond) {
      this.msg = msg;
      this.visible = true;
      let _this = this;
      if (timer) {
        clearInterval(timer)
      }
      timer = setInterval(() => {
        _this.visible = false;
      }, millisecond || 2000)
    }
  }
}
</script>

<style scoped lang="less">

._base_toast {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  ._mask_layer {
    width: 500px;
    height: 170px;
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 8px 30px 1px rgba(0, 0, 0, 0.3);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 20px;
    text-align: center;
  }
}

.cmp_toast-enter-active, .cmp_toast-leave-active {
  transition: opacity .5s;
}

.cmp_toast-enter-from,
.cmp_toast-leave-to {
  opacity: 0;
}

.cmp_toast-enter-to,
.cmp_toast-leave-from {
  opacity: 1;
}


</style>
