
import yellow_arrow from '@/assets/moudleArrow/blue.png'
import arrowRight from '@/assets/moudleArrow/arrow_right.png'
import computerBox from '@/assets/home/computer_box.png';
import finger from '@/assets/home/finger.png'
// import di from '@/assets/moudle1/bgi.png'

import Group36644 from '@/assets/moudle3/Group 36644.png'
import Group36647 from '@/assets/moudle3/Group 36647.png'
import Group from '@/assets/moudle3/Group.png'
import freepikDeviceInject50 from '@/assets/moudle3/freepik--Device--inject-50.png'
import freepikVideoFilesInject50 from '@/assets/moudle3/freepik--video-files--inject-50.png'
import illustration from '@/assets/moudle3/illustration.png'
import steel from '@/assets/home/steel.png'
import moudle3TopImg from '@/assets/home/moudle_3_top_img.png';

export default [
    {
        type: 'img', // 图片类型
        src: moudle3TopImg,
        style: {
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "556px",
            height: "222px",
        }
    },
    {
        type: 'img',
        src: Group36644,
        style: {
            position: "absolute",
            top: "276px",
            left: "377px",
            width: "210px",
            height: "273px",
        }
    },
    {
        type: 'img',
        src: Group36647,
        style: {
            position: "absolute",
            top: "304px",
            left: "720px",
            width: "70px",
            height: "131px",
        }
    },
    {
        type: 'img',
        src: Group,
        style: {
            position: "absolute",
            top: "58px",
            left: "630px",
            width: "257px",
            height: "189px",
        }
    },
    {
        type: 'img',
        src: freepikDeviceInject50,
        style: {
            position: "absolute",
            top: "299px",
            left: "1105px",
            width: "201px",
            height: "104px",
        }
    },
    {
        type: 'img',
        src: freepikVideoFilesInject50,
        style: {
            position: "absolute",
            top: "629px",
            left: "310px",
            width: "151px",
            height: "61px",
        }
    },
    {
        type: 'img',
        src: illustration,
        style: {
            position: "absolute",
            top: "607px",
            left: "-17px",
            width: "1475px",
            height: "170px",
        }
    },

    {
        type: 'img', // 图片类型
        src: steel,
        style: {
            position: "absolute",
            top: "434px",
            left: "1187px",
            width: "45px",
            height: "90px",
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "短视频营销的延展性思考",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "14px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "338px",
            left: "54px"
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "“种草”短视频",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "19px",
                width:"71px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "327px",
            left: "875px"
        }
    },
    {
        type: 'tip', // 黄色的标语卡片类型
        text: 'Part3',
        style: {
            position: "absolute",
            top: "252px",
            left: "47px",
            background: "#FAFF6A",
            paddingLeft: "49px",
            paddingRight: "49px"
        }
    },
    {
        type: 'tipFinger',
        src: finger,
        style: {
            position: "absolute",
            top: "273px",
            left: "152px"
        },
    },
    {
        type: 'tip',
        text: "结束第3阶段学习",
        style: {
            position: "absolute",
            top: "204px",
            left: "987px",
            color: "black",
            background: '#707070'
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id: 89,
        title:"娱乐内容的内容类型",
        sencondTitle:"娱乐内容的内容类型",
        text: '娱乐内容的内容类型',
        sync: 'D-1',
        style: {
            position: "absolute",
            top: "451px",
            left: "150px",
            background: "#0028BE",
            color:"white",
            fontSize: "16px",
            lineHeight: "30px",
            height: "32px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id: 90,
        title:"短视频内容的发展趋势",
        sencondTitle:"短视频内容的发展趋势",
        text: '短视频内容的发展趋势',
        sync: 'D-2',
        style: {
            position: "absolute",
            top: "550px",
            left: "150px",
            background: "#0028BE",
            color:"white",
            fontSize: "16px",
            lineHeight: "30px",
            height: "32px",
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id: 91,
        title:"人群及内容分类",
        sencondTitle:"人群及内容分类",
        text: '人群及内容分类',
        sync: 'D-3',
        style: {
            position: "absolute",
            top: "549px",
            left: "439px",
            background: "#0028BE",
            color:"white",
            zIndex: 100,
            fontSize: "16px",
            lineHeight: "30px",
            height: "32px"
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id: 92,
        title:"种草短视频的含义",
        sencondTitle:"种草短视频的含义",
        text: '种草短视频的含义',
        sync: 'D-4',
        style: {
            position: "absolute",
            top: "435px",
            left: "738px",
            background: "#0028BE",
            color:"white",
            zIndex: 100,
            fontSize: "16px",
            lineHeight: "30px",
            height: "32px"
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id: 93,
        title:"短视频种草三阶",
        sencondTitle:"短视频种草三阶",
        text: '短视频种草三阶',
        sync: 'D-5',
        style: {
            position: "absolute",
            top: "524px",
            left: "738px",
            background: "#0028BE",
            color:"white",
            fontSize: "16px",
            lineHeight: "30px",
            height: "32px",
            width:"133px"
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id: 94,
        title:"短视频“种草”与直播“带货”",
        sencondTitle:"短视频“种草”与直播“带货”",
        text: '短视频“种草”与直播“带货”',
        sync: 'D-6',
        style: {
            position: "absolute",
            top: "524px",
            left: "1100px",
            background: "#0028BE",
            color:"white",
            zIndex: 100,
            fontSize: "16px",
            lineHeight: "30px",
            height: "32px"
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id: 95,
        title:"短视频“种草”常见套路",
        sencondTitle:"短视频“种草”常见套路",
        text: '短视频“种草”常见套路',
        sync: 'D-7',
        style: {
            position: "absolute",
            top: "435px",
            left: "1100px",
            background: "#0028BE",
            color:"white",
            zIndex: 100,
            fontSize: "16px",
            lineHeight: "30px",
            height: "32px"
        }
    },
     {
        id: '1',
        type: 'arrow',
        direction: 'Right',
        src: yellow_arrow,
        style: {
            position: "absolute",
            top: "465px",
            left: "94px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '2',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "562px",
            left: "94px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '3',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "561px",
            left: "601px",
            transform: 'rotateY(180deg)',
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '4',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "448px",
            left: "908px",
            transform: 'rotateY(180deg)',
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '5',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "537px",
            left: "909px",
            transform: 'rotateY(180deg)',
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '6',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "537px",
            left: "1033px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '7',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "448px",
            left: "1033px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
];
