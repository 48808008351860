<template>
  <svg class="railway" :viewBox="`0 0 ${svgSize.width} ${svgSize.height}`">
    <path class="road-line" :d="inactiveRoadLine"></path>

    <path class="road-line active" :d="activeRoadLine"></path>

    <path class="dash-line" :d="dashLine"/>
  </svg>
</template>

<script setup>
import {computed, defineProps, watch} from "vue";

let stepData = require('./data/step.json');
let wisdomData = require('./data/wisdom.json');
let planData = require('./data/plan.json');
let originality = require('./data/originality.json')

const props = defineProps({
  type: {
    type: Number,
    default: 1,
  }, // 1 - 步步为营，2 - 学富五车、 3 - 有章有法
  currentStation: {
    type: Number,
    default: 0
  }, // 当前位置
})

const data = computed(() => [stepData, wisdomData, originality, planData][props.type - 1]);

const svgSize = computed(() => data.value.size);

// const svgWidth = computed(() => `${svgSize.value.width}px`);
// const svgHeight = computed(() => `${svgSize.value.height}px`);

const activeColor = computed(() => data.value.colors.active);
const inactiveColor = computed(() => data.value.colors.inactive);

watch(() => props.currentStation, (n) => {
  if (props.type === 3) {
    console.log("点点点点点点点点点" + n)
  }
})

const nodes = computed(() => {
  return [data.value.start, ...data.value.nodes, data.value.end];
})

const getLine = (points) => {
  const phases = points.map((point, index) => {
    if (index === 0) {
      return `M${point.coords.x} ${point.coords.y}`;
    }
    return point.path || `L${point.coords.x} ${point.coords.y}`;
  });
  return phases.join(' ');
};
const currentIndex = computed(() => {
      if (props.type === 3) {
        console.log("PROPS-------- D点学习进度ID", props.currentStation)
      }
      return props.currentStation === data.value.nodes.length ? props.currentStation + 1 : props.currentStation
    }
)

const inactiveRoadLine = computed(() => getLine(nodes.value.slice(currentIndex.value)));

const activeRoadLine = computed(() => {
  if (props.type === 3) {

    console.log("NODE---3")
    console.log(nodes)
  }
  console.log(nodes.value.slice(0, currentIndex.value + 1))
  return getLine(nodes.value.slice(0, currentIndex.value + 1))
});
const dashLine = getLine(nodes.value);
</script>

<style scoped lang="less">

.railway {
  position: absolute;

  .road-line {
    stroke: v-bind(inactiveColor);
    stroke-width: 26;
    stroke-linecap: round;
    fill: transparent;

    &.active {
      stroke: v-bind(activeColor);
    }
  }

  .dash-line {
    fill: transparent;
    stroke: #FFFFFF;
    stroke-width: 1;
    stroke-dasharray: 10 5;
  }
}

</style>
