<template>
  <div class="mask" v-if="show" @click="hide">
    <!--输入-->
    <template v-if="cerUrl == null">
      <div class="_input" @click="(event) => event.stopPropagation()">
        <div class="_input_box">
          <div class="_cell">
            <div class="_label">
              姓名：
            </div>
            <div class="_wrapper">
              <input :maxlength="20" v-model="form.name" placeholder="请输入姓名"/>
            </div>
          </div>
          <div class="_cell">
            <div class="_label">
              身份证号：
            </div>
            <div class="_wrapper">
              <input :maxlength="18" v-model="form.idCard" placeholder="请输入身份证号码"/>
            </div>
          </div>
        </div>
        <div @click="doGenerate" class="_confirm">确认</div>
      </div>
    </template>
    <template v-else>
      <div class="_certificate">
        <img @click="(event) => event.stopPropagation()" :src="cerUrl"/>
      </div>
    </template>
  </div>
</template>

<script>
import {defineComponent, reactive, toRefs} from "vue";
import {request} from "@/api/request";

export default defineComponent({
  name: "CerGetter",
  setup() {

    const state = reactive({
      form: {
        name: null,
        idCard: null,
      },
      cerUrl: null,
      show: false,
    })

    let stopRequest = false;

    const init = () => {

      state.show = true;

      request('/iredvlog/queryCertificate', {}, 'GET').then(res => {
        if (res.success) {
          state.cerUrl = res.result.certificateUrl
        }
      })

    }

    const doGenerate = (event) => {

      event.stopPropagation();

      if (stopRequest) {
        return
      }

      if (!state.form.name) {
        return alert("请输入姓名");
      }

      if (!state.form.idCard) {
        return alert("请输入身份证号")
      }
      stopRequest = true;
      request('/iredvlog/addCertificate', {...state.form}, 'POST').then(res => {
        if (res.success) {
          console.log(res)
          state.cerUrl = res.result.certificateUrl
        } else {
          alert(res.message)
        }
      }).finally(() => {
        stopRequest = false;
      })

    }

    const hide = (event) => {
      state.show = false;
      event.stopPropagation();
    }

    return {
      ...toRefs(state),
      init,
      doGenerate,
      hide
    }
  }
})
</script>

<style scoped lang="less">
.mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px) brightness(40%);
  top: 0px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ._input {
    width: 619px;
    height: 592px;
    background-image: url("../../assets/cer/bg_img.png");
    background-size: 100% 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    ._input_box {
      width: 327px;
      height: 70px;
      position: absolute;
      top: 370px;

      ._cell {
        height: 50%;
        width: 100%;
        display: flex;
        align-items: center;

        ._label {
          width: 110px;
          text-align: right;
          font-size: 21px;
          font-weight: 400;
          color: #000000;
          line-height: 42px;
        }

        ._wrapper {
          width: calc(100% - ._label[width]);
          box-sizing: border-box;
          text-align: left;

          input {
            width: calc(100% - 10px);
            height: 25px;
            background: #F2F2F2;
            border-radius: 4px;
            opacity: 1;
            border: 1px solid #EAEAEA;
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            line-height: 44px;
          }
        }
      }
    }

    ._confirm {
      cursor: pointer;
      bottom: 33px;
      position: absolute;
      width: 291px;
      height: 53px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      font-weight: 600;
      color: #8E4100;
    }
  }

  ._certificate {
    width: 60%;
    height: 68%;
  }
}

input, .ar {
  outline: none;
}
</style>
