<template>
  <div v-if="elementAttr.type == 'imgDiv'" :style="elementAttr.style" class="imgDiv">
    <img :src="elementAttr.src"/>
    <span>{{ elementAttr.text }}</span>
  </div>

  <img v-if="elementAttr.type === 'tipFinger'" :src="elementAttr.src" :style="elementAttr.style" class="finger">

  <div v-if="elementAttr.type === 'computerBox'" :style="elementAttr.style" class="computerBox">
    <div :style="elementAttr.text.style">{{ elementAttr.text.info }}</div>
  </div>

  <img v-if="elementAttr.type == 'img'" :src="elementAttr.src" :style="elementAttr.style">
  <div :class="`arrow ${elementAttr.direction}`" v-if="elementAttr.type == 'arrow'" :style="elementAttr.style">
    <div>{{ elementAttr.id }}</div>
  </div>

  <div v-if="elementAttr.type == 'tip'" class="tip" :style="elementAttr.style">{{ elementAttr.text }}</div>
  <div v-if="elementAttr.type == 'tipEnd'" class="tip" :style="elementAttr.style">{{ elementAttr.text }}</div>


  <div v-if="elementAttr.type == 'menuTip'" :class='`menu_tip ${progressChange(elementAttr.sync)}`'
       :style="elementAttr.style">
    <!-- 有章有法界面 标签前头标 -->
    <img v-if="(elementAttr.sync).indexOf('C') != -1" :src="c_icon(elementAttr.sync)" class="imgFilter"/>
    <!-- 前两个界面的子菜单弹出交互 -->
    <button v-if="(elementAttr.sync).indexOf('A') != -1" class="menu_tip_btn"
            @click="getMenusList(elementAttr.sync)"></button>
    <!-- 有章有法界面 直接出弹框 -->
    <button class="menu_tip_C" v-else @click="getContent(elementAttr)"></button>
    <!-- 标签主标题 -->
    <span>{{ elementAttr.text }}</span>
    <!-- 界面标签的下拉箭头要跟着是否解锁更换样式 -->
    <img v-if="(elementAttr.sync).indexOf('A') != -1" class="menu_tip_icon"
         :src="arrowSelector(elementAttr.sync)" alt="">
    <!-- 前两个界面的下拉菜单 框 -->
    <div v-if="(elementAttr.sync).indexOf('A') != -1" :class="`menus`">
      <div v-for="(item, index) in menusList" :class="`menu_item ${subTitleProgress(elementAttr.sync,item.id)}`"
           :key="index" @click="getContent(item)" @mouseenter="mouseInThis(index)">
        <div class="menu_item_index">{{ index + 1 }}</div>
        <span>{{ item.sencondTitle }}</span>
        <img v-if="goldPonint >= 0 && goldPonint == index" class="menu_item_select_img"
             src="@/assets/home/icon_select.png">
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, watch} from "vue";
import {request} from "../api/request"
import {useStore} from 'vuex'

export default defineComponent({
  name: "moudleElements",
  props: {
    elementAttr: Object
  },
  setup(props) {

    let menusList = []
    let goldPonint = 0

    const store = useStore()

    const state = reactive({
      menusList,
      goldPonint,
      progress: {
        theme: 'A-1',
        subtitle: '',
        time: '',
      },
      black: require('@/assets/home/icon_triangular.png'),
      white: require('@/assets/home/icon_triangular_white.png'),
      progressTxt: ''
    });


    onMounted(() => {
      state.progress.theme = store._state.data.userNodeRecordId;
      state.progress.subtitle = +store._state.data.knowledgePointId
      state.progress.time = +store._state.data.browsingTime
    })

    watch(() => store._state.data.progress, () => {
      state.progress.theme = store._state.data.userNodeRecordId;
      state.progress.subtitle = +store._state.data.knowledgePointId;
      state.progress.time = +store._state.data.browsingTime;
    })


    const getContent = function (item) {

      if (state.progress.theme.split('-')[0] != 'B' && item.sync.split('-')[0] == 'B' && state.progress.theme.split('-')[0] == 'A') {
        return alert('该课程未解锁');
      }

      if (state.progress.theme.split('-')[0] != 'D' && item.sync.split('-')[0] == 'D' && (state.progress.theme.split('-')[0] == 'A' || state.progress.theme.split('-')[0] == 'B'  )) {
        return alert('该课程未解锁');
      }

      if (state.progress.theme.split('-')[0] != 'C' && item.sync.split('-')[0] == 'C' && (state.progress.theme.split('-')[0] == 'A' || state.progress.theme.split('-')[0] == 'B' || state.progress.theme.split('-')[0] == 'D' )) {
        return alert('该课程未解锁');
      }

      if (state.progress.theme.split('-')[0] == item.sync.split('-')[0] && (item.id > state.progress.subtitle)) {
        if (item.sync.split('-')[0] == 'C') {
          console.log('这是C的界面')
        }
        return alert('该课程未解锁');
      }

      var contentAll = {
        id: item.id,
        title: item.title,
        sencondTitle: item.sencondTitle,
        content: item.content,
        sync: item.sync,
        cover: '',
        videoDtoList: item.videoDtoList,
        type: item.type
      }

      if ((item.sync).indexOf('B') != -1 || (item.sync).indexOf('C') != -1 || (item.sync).indexOf('D') != -1) {
        request(`/iredvlog/menuList?sync=${item.sync}`, '', 'GET').then(data => {
          contentAll.videoDtoList = data.result.videoDtoList
          // state.menusList = data.result;
          // console.log(state.menusList)
          this.$emit("getContent", contentAll)

        })
      }
      if ((item.sync).indexOf('B') == -1 && (item.sync).indexOf('C') == -1 && (item.sync).indexOf('D') == -1) {
        this.$emit("getContent", contentAll)

      }


    }

    // 大标题进度判断
    const progressChange = function (item) {
      switch (state.progress.theme.split('-')[0]) {
        case 'A':
          if (item.split('-')[0] == 'A' && +item.split('-')[1] <= +state.progress.theme.split('-')[1]) return '';
          if (item.split('-')[0] == 'A') return "menu_tip_unactive_1";
          if (item.split('-')[0] == 'B') return 'menu_tip_unactive_2';
          if (item.split('-')[0] == 'D') return 'menu_tip_unactive_3';
          if (item.split('-')[0] == 'C') return 'menu_tip_unactive_4';
          return ''
        case 'B':
          if (item.split('-')[0] == 'A') return ''
          if (item.split('-')[0] == 'B' && +item.split('-')[1] <= +state.progress.theme.split('-')[1]) return '';
          if (item.split('-')[0] == 'B') return 'menu_tip_unactive_2';
          if (item.split('-')[0] == 'D') return 'menu_tip_unactive_3';
          return 'menu_tip_unactive_4'
        case 'D':
          if (item.split('-')[0] == 'A') return ''
          if (item.split('-')[0] == 'B') return '';
          if (item.split('-')[0] == 'D' && +item.split('-')[1] <= +state.progress.theme.split('-')[1]) return '';
          if (item.split('-')[0] == 'D') return 'menu_tip_unactive_3';
          return 'menu_tip_unactive_4'
        case 'C':
          if (item.split('-')[0] == 'A') return ''
          if (item.split('-')[0] == 'B') return '';
          if (item.split('-')[0] == 'D') return '';
          if (item.split('-')[0] == 'C' && +item.split('-')[1] <= +state.progress.theme.split('-')[1]) return '';
          return 'menu_tip_unactive_4'
      }
    }

    // 有章有法 小标签 颜色判断
    const c_icon = function (item) {
      // state.progress.theme = 'C-3'
      if (state.progress.theme.split('-')[0] == 'C') {
        if (item.split('-')[0] == 'C' && +item.split('-')[1] <= +(state.progress.theme).split('-')[1]) {
          return props.elementAttr.src
        } else {
          return props.elementAttr.srca
        }
      }
      return props.elementAttr.srca

    }

    // 大标题 上下箭头判断
    const arrowSelector = function (item) {

      switch ((state.progress.theme).split('-')[0]) {
        case 'A':
          if (item.split('-')[0] == 'A' && +item.split('-')[1] <= +state.progress.theme.split('-')[1]) return state.white;
          return state.white;
        case 'B':
          if (item.split('-')[0] == 'A') return state.white;
          if (item.split('-')[0] == 'B' && +item.split('-')[1] <= +state.progress.theme.split('-')[1]) return state.white;
          return state.white
        case 'D':
          if (item.split('-')[0] == 'A') return state.white;
          if (item.split('-')[0] == 'B') return state.white;
          if (item.split('-')[0] == 'D' && +item.split('-')[1] <= +state.progress.theme.split('-')[1]) return state.white;
          return state.white
        case 'C':
          if (item.split('-')[0] == 'A') return state.white;
          if (item.split('-')[0] == 'B') return state.white;
          if (item.split('-')[0] == 'D') return state.white;
          return ''

      }

    }

    // 二级标题 进度判断
    const subTitleProgress = function (progress, id) {
      switch (state.progress.theme.split('-')[0]) {
        case 'A':
          if (progress.split('-')[0] == 'A' && id <= state.progress.subtitle) return 'menu_item_a';
          return ''

        case 'B':
          if (progress.split('-')[0] == 'A') return 'menu_item_a';
          if (progress.split('-')[0] == 'B' && id <= state.progress.subtitle) return 'menu_item_b'
          return ''

        case 'D':
          if (progress.split('-')[0] == 'A') return 'menu_item_a';
          if (progress.split('-')[0] == 'B') return 'menu_item_b';
          if (progress.split('-')[0] == 'D' && id <= state.progress.subtitle) return 'menu_item_d'
          return ''

        case 'C':
          if (progress.split('-')[0] == 'A') return 'menu_item_a';
          if (progress.split('-')[0] == 'B') return 'menu_item_b';
          if (progress.split('-')[0] == 'D') return 'menu_item_d';
          return ''
      }
    }


    const getMenusList = function (item) {
      request(`/iredvlog/menuList?sync=${item}`, '', 'GET').then(data => {
        state.menusList = data.result;
        switch (state.progress.theme.split('-')[0]) {
          case 'A':
            if (item.split('-')[0] == 'A' && +item.split('-')[1] <= +state.progress.theme.split('-')[1]) return;
            if (item.split('-')[0] == 'A') return alert('该课程未解锁');
            if (item.split('-')[0] == 'B') return alert('该课程未解锁');
            if (item.split('-')[0] == 'D') return alert('该课程未解锁');
            if (item.split('-')[0] == 'C') return alert('该课程未解锁');
            return;
          case 'B':
            if (item.split('-')[0] == 'A') return;
            if (item.split('-')[0] == 'B' && +item.split('-')[1] <= +state.progress.theme.split('-')[1]) return;
            if (item.split('-')[0] == 'B') return alert('该课程未解锁');
            if (item.split('-')[0] == 'D') return alert('该课程未解锁');
            if (item.split('-')[0] == 'C') return alert('该课程未解锁');
            return alert('该课程未解锁');
          case 'D':
            if (item.split('-')[0] == 'A') return;
            if (item.split('-')[0] == 'D' && +item.split('-')[1] <= +state.progress.theme.split('-')[1]) return;
            if (item.split('-')[0] == 'D') return alert('该课程未解锁');
            if (item.split('-')[0] == 'C') return alert('该课程未解锁');
            return alert('该课程未解锁');
          case 'C':
            if (item.split('-')[0] == 'A') return;
            if (item.split('-')[0] == 'B') return;
            if (item.split('-')[0] == 'D') return;
            if (item.split('-')[0] == 'C' && +item.split('-')[1] <= +state.progress.theme.split('-')[1]) return;
            return alert('该课程未解锁');
        }
        // switch (state.progress.theme.split('-')[0]) {
        //   case 'A':
        //     if (item.split('-')[0] == 'A' && +item.split('-')[1] <= +state.progress.theme.split('-')[1]) return;
        //     if (item.split('-')[0] == 'A') return alert('该课程未解锁');
        //     if (item.split('-')[0] == 'B') return alert('该课程未解锁');
        //     if (item.split('-')[0] == 'D') return alert('该课程未解锁');
        //     if (item.split('-')[0] == 'C') return alert('该课程未解锁');
        //     return;
        //   case 'B':
        //     if (item.split('-')[0] == 'A') return;
        //     if (item.split('-')[0] == 'D') return alert('该课程未解锁');
        //     if (item.split('-')[0] == 'B' && +item.split('-')[1] <= +state.progress.theme.split('-')[1]) return;
        //     if (item.split('-')[0] == 'B') return alert('该课程未解锁');
        //     return alert('该课程未解锁');
        //   case 'D':
        //     if (item.split('-')[0] == 'A') return;
        //     if (item.split('-')[0] == 'B') return;
        //     if (item.split('-')[0] == 'D' && +item.split('-')[1] <= +state.progress.theme.split('-')[1]) return;
        //     if (item.split('-')[0] == 'D') return alert('该课程未解锁');
        //     return alert('该课程未解锁');
        //   case 'C':
        //     if (item.split('-')[0] == 'A') return;
        //     if (item.split('-')[0] == 'B') return;
        //     if (item.split('-')[0] == 'D') return;
        //     if (item.split('-')[0] == 'C' && +item.split('-')[1] <= +state.progress.theme.split('-')[1]) return;
        //     return alert('该课程未解锁');
        // }
        console.log(state.menusList)
      })

    }

    const mouseInThis = function (item) {
      state.goldPonint = item
    }

    return {
      ...toRefs(state),
      getContent,
      getMenusList,
      mouseInThis,
      progressChange,
      c_icon,
      arrowSelector,
      subTitleProgress
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.computerBox {
  width: 118px;
  height: 90px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tip {
  background: #FFDA26;
  border-radius: 10px;
  filter: blur(undefinedpx);
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 16px;
}

.menu_tip {
  border-image: radial-gradient(circle, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
  background: #B88900;
  border-radius: 10px;
  filter: blur(undefinedpx);
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 16px;
  cursor: pointer;

  .menu_tip_btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    color: #FFFFFF;

    &:focus ~ .menu_tip_icon {
      transform: rotate(180deg);
    }

    &:focus ~ .menus {
      display: flex;
    }
  }

  .menu_tip_C {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }


  .menu_tip_icon {
    width: 9px;
    margin-left: 4px;
    transition: all 0.3s;
    transform: rotate(0deg);
  }

  .menus {
    min-width: 366px;
    background: rgba(255, 255, 255, 0.28);
    backdrop-filter: blur(10px) brightness(40%);
    border-radius: 6px;
    padding: 20px 16px;
    display: none;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: absolute;
    top: 52px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 7;

    &:hover {
      display: flex;
    }

    .menu_item {
      border-radius: 24px;
      border: 1px solid white;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: white;
      padding: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      position: relative;
      // &:hover {
      //   color: #FAFF6A;
      //   border-color: #FAFF6A;

      //   .menu_item_index {
      //     background: #FAFF6A;
      //   }
      // }

      .menu_item_index {
        width: 16px;
        height: 16px;
        border-radius: 16px;
        background: white;
        text-align: center;
        color: black;
        font-size: 12px;
        line-height: 16px;
        position: absolute;
        top: -4px;
        left: -4px;
      }

      // &.select {
      //   color: #FAFF6A;
      //   border-color: #FAFF6A;

      //   .menu_item_index {
      //     background: #FAFF6A;
      //   }
      // }

      .menu_item_select_img {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 32px;
        right: 10px;
        transition: all 2s;
        animation: myPoint 2s infinite;
      }

      @keyframes myPoint {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(2);
        }
        100% {
          transform: scale(1);
        }
      }
    }

    .menu_item_a {
      border: 1px solid #FAFF6A;
      color: #FAFF6A;

      .menu_item_index {
        background: #FAFF6A;
      }
    }

    .menu_item_d {
      border: 1px solid #FAFF6A;
      color: #FAFF6A;

      .menu_item_index {
        background: #FAFF6A;
      }
    }

    .menu_item_b {
      border: 1px solid #FFDA26;
      color: #FFDA26;

      .menu_item_index {
        background: #FFDA26;
      }
    }

    .subtitle {
      &:hover {
        color: #fff;
        border-color: #fff;

        .menu_item_index {
          background: #fff;
        }
      }

      &.select {
        color: #fff;
        border-color: #fff;

        .menu_item_index {
          background: #fff;
        }
      }
    }


    &::after {
      content: "";
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid rgba(255, 255, 255, 0.28);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: -20px;
    }
  }


}

.menu_tip_unactive {
  background: #192F2B;
  color: #fff;

  .menu_tip_btn {
    &:focus ~ .menu_tip_icon {
      transform: rotate(0deg);
    }

    &:focus ~ .menus {
      display: none;
    }
  }
}

.menu_tip_unactive_1 {
  background: #382C06 !important;
  color: #fff;

  .menu_tip_btn {
    &:focus ~ .menu_tip_icon {
      transform: rotate(0deg);
    }

    &:focus ~ .menus {
      display: none;
    }
  }
}

.menu_tip_unactive_2 {
  background: #062807 !important;
  color: #fff;

  .menu_tip_btn {
    &:focus ~ .menu_tip_icon {
      transform: rotate(0deg);
    }

    &:focus ~ .menus {
      display: none;
    }
  }
}

.menu_tip_unactive_3 {
  background: #02115A !important;
  color: #fff;

  .menu_tip_btn {
    &:focus ~ .menu_tip_icon {
      transform: rotate(0deg);
    }

    &:focus ~ .menus {
      display: none;
    }
  }
}

.menu_tip_unactive_4 {
  background: #162F3E !important;
  color: #fff;

  .menu_tip_btn {
    &:focus ~ .menu_tip_icon {
      transform: rotate(0deg);
    }

    &:focus ~ .menus {
      display: none;
    }
  }
}

.menu_tip_unactive_yelow {
  background: #382C06 !important;
  color: #fff;

  .menu_tip_btn {
    &:focus ~ .menu_tip_icon {
      transform: rotate(0deg);
    }

    &:focus ~ .menus {
      display: none;
    }
  }
}

.menu_tip_unactive_blue {
  background: #162F3E !important;
  color: #fff;

  .menu_tip_btn {
    &:focus ~ .menu_tip_icon {
      transform: rotate(0deg);
    }

    &:focus ~ .menus {
      display: none;
    }
  }
}


.arrow {
  width: 44px;
  height: 37px;
  text-align: center;
  line-height: 32px;
  padding-right: 5px;
  color: #222222 !important;
}

.imgDiv {
  height: 20px;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

.imgFilter {
  margin-right: 5px;
}

// .Right:hover{
//   background: url('../assets/moudleArrow/arrow_right_active.png') center !important;
// }

// .Left:hover{
//   background: url('../assets/moudleArrow/arrow_left_active.png') center !important;
// }

.Up {
  height: 41px;
  // padding-top: 10px;
  // padding-left: 5px;
  div {
    transform: rotateZ(90deg);
  }
}

.Left {
  // padding-left: 5px;
  div {
    transform: rotateY(180deg);
  }
}

.Down {
  height: 41px;
  // padding-left: 5px;
  // padding-bottom: 5px;
  div {
    transform: rotateZ(270deg);
  }
}

// .Up:hover{
//   background: url('../assets/moudleArrow/arrow_up_active.png') center !important;
// }

// .Down:hover{
//   background: url('../assets/moudleArrow/arrow_down_active.png') center !important;
// }
</style>
