import moudle1TopImg from '@/assets/home/moudle_2_top_img.png';
import computerBox from '@/assets/home/computer_box.png';
import finger from '@/assets/home/finger.png'
// import arrowUp from '@/assets/moudleArrow/arrow_up.png'
// import arrowLeft from '@/assets/moudleArrow/arrow_left.png'
import arrowRight from '@/assets/moudleArrow/arrow_right.png'

import yellow_arrow from '@/assets/moudleArrow/green.png'
import freepik from '@/assets/moudle2/freepik--board--inject-540.png';
import Group1 from '@/assets/moudle2/Group (1).png'
import Group36637 from '@/assets/moudle2/Group 36637.png'
import Group36643 from '@/assets/moudle2/Group 36643.png'
import Group36644 from '@/assets/moudle2/Group 36644.png'
import Group from '@/assets/moudle2/Group.png'
import illustration1 from '@/assets/moudle1/illustration1.png'
// import di from '@/assets/moudle1/bgi.png'
export default [
    {
        type: 'img',
        src: moudle1TopImg,
        style: {
            position: "absolute",
            top: "0px",
            left: "-52px",
            width: "566px",
            height: "222px",
        }
    },
    {
        type: 'img', // 图片类型
        src: illustration1,
        style: {
            position: "absolute",
            top: "624px",
            left: "-158px",
            width: "1971px",
            height: "153px",
        }
    },
    // {
    //     type: 'img', // 图片类型
    //     src: di,
    //     style: {
    //         position: "absolute",
    //         top: "-141px",
    //         left: "-111px",
    //         width: "1909px",
    //         height: "900px",
    //         zIndex:-20
    //     }
    // },
    {
        type: 'img',
        src: freepik,
        style: {
            position: "absolute",
            top: "477px",
            left: "1060px",
            width: "106px",
            height: "135px",
            // zIndex:-1
        }
    },
    {
        type: 'img',
        src: Group1,
        style: {
            position: "absolute",
            top: "227px",
            left: "1483px",
            width: "60px",
            height: "59px",
            zIndex:105
        }
    },
    {
        type: 'img',
        src: Group36637,
        style: {
            position: "absolute",
            top: "355px",
            left: "131px",
            width: "112px",
            height: "91px",
        }
    },
    {
        type: 'img',
        src: Group36643,
        style: {
            position: "absolute",
            top: "429px",
            left: "433px",
            width: "266px",
            height: "113px",
        }
    },
    {
        type: 'img',
        src: Group36644,
        style: {
            position: "absolute",
            top: "57px",
            left: "1042px",
            width: "98px",
            height: "73px",
        }
    },
    {
        type: 'img',
        src: Group,
        style: {
            position: "absolute",
            top: "55px",
            left: "633px",
            width: "261px",
            height: "177px",
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "短视频的运营工具",
            // info:'的运营工具',
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "21px",
                width:"100px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "306px",
            left: "-3px"
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "账号级别及平台算法",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "14px",
                width:" 93px",
                textIndent: "4px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "622px",
            left: "131px"
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "平台推荐机制及核心数据",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "14px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "434px",
            left: "259px"
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "账号定位",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "14px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "443px",
            left: "761px"
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "短视频制作",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "14px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "443px",
            left: "933px"
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "脚本制作",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "14px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "9px",
            left: "1326px"
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "营销文案与短视频文案的特点",
            info2:'1212',
            style: {
                fontSize: "15.5px",
                fontWeight: "800",
                paddingBottom:"20px",
                width:"89px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "313px",
            left: "1326px"
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "短视频文案撰写原则及方法",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "14px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "668px",
            left: "1379px"
        }
    },
    {
        type: 'computerBox',
        text: {
            info: "短视频文案撰写技巧",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "20px",
                width:"100px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "413px",
            left: "1609px"
        }
    },
    {
        type: 'tip', // 黄色的标语卡片类型
        text: 'Part2',
        style: {
            position: "absolute",
            top: "248px",
            left: "-12px",
            background: "#FAFF6A",
            paddingLeft: "49px",
            paddingRight: "49px"
        }
    },
    {
        type: 'tipFinger',
        src: finger,
        style: {
            position: "absolute",
            top: "268px",
            left: "91px"
        },
    },
    {
        type: 'tip',
        text: "结束第2阶段学习",
        style: {
            position: "absolute",
            top: "114px",
            left: "1600px",
            color: "black",
            background: '#707070'
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:58,
        title:"运营平台",
        sencondTitle:"运营平台",
        text: '运营平台',
        sync: 'B-1',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            width:"90px",
            position: "absolute",
            top: "441px",
            left: "87px",
            background: "#008504",
            color:"white"
        },
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:59,
        title:"热浪数据",
        sencondTitle:"热浪数据",
        text: '热浪数据',
        sync: 'B-2',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            width:"90px",
            position: "absolute",
            top: "510px",
            left: "87px",
            background: "#008504",
            color:"white"
        }
    },
    // tod
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:60,
        title:"平台等级",
        sencondTitle:"平台等级",
        text: '平台等级',
        sync: 'B-3',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            width:"93px",
            position: "absolute",
            top: "611px",
            left: "348px",
            background: "#008504",
            color:"white",
            zIndex: 102
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:61,
        title:"抖音与快手",
        sencondTitle:"抖音与快手",
        text: '抖音与快手',
        sync: 'B-4',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            width:"93px",
            position: "absolute",
            top: "542px",
            left: "348px",
            background: "#008504",
            color:"white",
            zIndex: 102
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:62,
        title:"推荐机制及平台算法",
        sencondTitle:"推荐机制及平台算法",
        text: '推荐机制及平台算法',
        sync: 'B-5',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "351px",
            left: "348px",
            background: "#008504",
            color:"white",
            zIndex: 102
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:63,
        title:"抖音的内容分发流程",
        sencondTitle:"抖音的内容分发流程",
        text: '抖音的内容分发流程',
        sync: 'B-6',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "281px",
            left: "348px",
            background: "#008504",
            color:"white",
            zIndex: "102"
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:64,
        title:"快手的内容分发流程",
        sencondTitle:"快手的内容分发流程",
        text: '快手的内容分发流程',
        sync: 'B-7',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "281px",
            left: "601px",
            background: "#008504",
            color:"white",
            zIndex: 102
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:65,
        title:"推荐算法：名词解释",
        sencondTitle:"推荐算法：名词解释",
        text: '推荐算法：名词解释',
        sync: 'B-8',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "351px",
            left: "601px",
            background: "#008504",
            color:"white",
            zIndex: 99
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:66,
        title:"垂直领域定位",
        sencondTitle:"垂直领域定位",
        text: '垂直领域定位',
        sync: 'B-9',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "542px",
            left: "648px",
            background: "#008504",
            color:"white",
            zIndex: 0
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:67,
        title:"人物设计",
        sencondTitle:"人物设计",
        text: '人物设计',
        sync: 'B-10',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "611px",
            left: "852px",
            background: "#008504",
            color:"white",
            zIndex: 0
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:68,
        title:"人设定位",
        sencondTitle:"人设定位",
        text: '人设定位',
        sync: 'B-11',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            width:"90px",
            position: "absolute",
            top: "612px",
            left: "1022px",
            background: "#008504",
            color:"white",
            zIndex: 0
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:70,
        title:"短视频拍摄逻辑",
        sencondTitle:"短视频拍摄逻辑",
        text: '短视频拍摄逻辑',
        sync: 'B-12',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "356px",
            left: "1022px",
            background: "#008504",
            color:"white",
            zIndex: 0
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:71,
        title:"运镜与转场",
        sencondTitle:"运镜与转场",
        text: '运镜与转场',
        sync: 'B-13',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "281px",
            left: "1022px",
            background: "#008504",
            color:"white",
            zIndex: 0
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:73,
        title:"景别与视角",
        sencondTitle:"景别与视角",
        text: '景别与视角',
        sync: 'B-14',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "212px",
            left: "1022px",
            background: "#008504",
            color:"white",
            zIndex: 100
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:74,
        title:"短视频后期制作",
        sencondTitle:"短视频后期制作",
        text: '短视频后期制作',
        sync: 'B-15',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "127px",
            left: "1022px",
            background: "#008504",
            color:"white",
            zIndex: 120
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:81,
        title:"脚本的作用",
        sencondTitle:"脚本的作用",
        text: '脚本的作用',
        sync: 'B-16',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "156px",
            left: "1229px",
            background: "#008504",
            color:"white",
            zIndex: 100
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:82,
        title:"脚本的撰写流程",
        sencondTitle:"脚本的撰写流程",
        text: '脚本的撰写流程',
        sync: 'B-17',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "236px",
            left: "1197px",
            background: "#008504",
            color:"white",
            zIndex: 0
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:83,
        title:"标题核心作用",
        sencondTitle:"标题核心作用",
        text: '标题核心作用',
        sync: 'B-18',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "422px",
            left: "1211px",
            background: "#008504",
            color:"white",
            zIndex: 0
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:84,
        title:"营销文案举例",
        sencondTitle:"营销文案举例",
        text: '营销文案举例',
        sync: 'B-19',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "524px",
            left: "1211px",
            background: "#008504",
            color:"white",
            zIndex: 0
        }
    }, {
        type: 'menuTip', // 黄色的标语卡片类型
        id:85,
        title:"短视频文案",
        sencondTitle:"短视频文案",
        text: '短视频文案',
        sync: 'B-20',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "650px",
            left: "1513px",
            background: "#008504",
            color:"white",
            // zIndex: 100
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:86,
        title:"短视频标题文案撰写",
        sencondTitle:"短视频标题文案撰写",
        text: '短视频标题文案撰写',
        sync: 'B-21',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "556px",
            left: "1449px",
            background: "#008504",
            color:"white",
            zIndex: 0
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:87,
        title:"用户思维",
        sencondTitle:"用户思维",
        text: '用户思维',
        sync: 'B-22',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "341px",
            left: "1529px",
            background: "#008504",
            color:"white",
            zIndex: 100
        }
    },
    {
        type: 'menuTip', // 黄色的标语卡片类型
        id:88,
        title:"方法技巧",
        sencondTitle:"方法技巧",
        text: '方法技巧',
        sync: 'B-23',
        style: {
            fontSize: "16px",
            lineHeight: "30px",
            height:"32px",
            position: "absolute",
            top: "259px",
            left: "1529px",
            background: "#008504",
            color:"white",
            zIndex: 100
        }
    },
    {
        id: '1',
        type: 'arrow',
        direction: 'Right',
        src: yellow_arrow,
        style: {
            position: "absolute",
            top: "452px",
            left: "36px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '2',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "516px",
            left: "36px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '3',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "621px",
            left: "296px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '4',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "552px",
            left: "296px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '5',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "361px",
            left: "296px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '6',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "290px",
            left: "296px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '7',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "290px",
            left: "793px",
            transform: 'rotateY(180deg)',
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },

    {
        id: '8',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "361px",
            left: "793px",
            transform: 'rotateY(180deg)',
            background: `url(${yellow_arrow}) center no-repeat`
        }
    }, {
        id: '9',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "552px",
            left: "793px",
            transform: 'rotateY(180deg)',
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },


    {
        id: '10',
        type: 'arrow',
        direction: 'right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "621px",
            left: "799px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '11',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "620px",
            left: "970px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '12',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "361px",
            left: "970px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '13',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "290px",
            left: "970px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '14',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "220px",
            left: "970px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },

    {
        id: '15',
        type: 'arrow',
        direction: 'Right',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "138px",
            left: "970px",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '16',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "165px",
            left: "1357px",
            transform: "rotateY(180deg)",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '17',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "246px",
            left: "1357px",
            transform: "rotateY(180deg)",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '18',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "431px",
            left: "1357px",
            transform: "rotateY(180deg)",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '19',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "531px",
            left: "1357px",
            transform: "rotateY(180deg)",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '20',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "660px",
            left: "1641px",
            transform: "rotateY(180deg)",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },

    {
        id: '21',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "566px",
            left: "1641px",
            transform: "rotateY(180deg)",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '22',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "350px",
            left: "1641px",
            transform: "rotateY(180deg)",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    },
    {
        id: '23',
        type: 'arrow',
        direction: 'Left',
        src: arrowRight,
        style: {
            position: "absolute",
            top: "270px",
            left: "1641px",
            transform: "rotateY(180deg)",
            background: `url(${yellow_arrow}) center no-repeat`
        }
    }
];
