import moudle4TopImg from "@/assets/home/moudle_4_top_img.png";

import buttonHowWhite from "@/assets/moudle4/button_how.png";
import buttonPeaWhite from "@/assets/moudle4/button_pea.png";
import buttonSearchWhite from "@/assets/moudle4/button_search.png";
import buttonVideoWhite from "@/assets/moudle4/button_video.png";

import creama from "@/assets/moudle4/Camera.png";
import film from "@/assets/moudle4/film.png";
import freepik from "@/assets/moudle4/freepik.png";
import groupBack2 from "@/assets/moudle4/Group_back2.png";
import groupGear from "@/assets/moudle4/Group_gear.png";
import groupHouse1 from "@/assets/moudle4/Group_house1.png";
import groupHome1 from "@/assets/moudle4/GroupHome1.png";
import judge from "@/assets/moudle4/Judge.png";
import rafiki from "@/assets/moudle4/rafiki.png";
import sheets from "@/assets/moudle4/sheets.png";

import steel from "@/assets/home/steel.png";

// import arrowUp from '@/assets/moudleArrow/arrow_up.png'
import arrowRight from "@/assets/moudleArrow/arrow_right.png";
// import arrowLeft from '@/assets/moudleArrow/arrow_left.png'
import arrowDown from "@/assets/moudleArrow/arrow_down.png";

import blue from "@/assets/moudleArrow/blue.png";
import one from '@/assets/moudle1/one.png'
import computerBox from '@/assets/home/computer_box.png';
import finger from '@/assets/home/finger.png'

export default [{
    type: "img",
    src: moudle4TopImg,
    style: {
        position: "absolute",
        top: "5px",
        left: "8px",
        width: "506px",
        height: "192px",
    },
},
    // pasterpaper
    {
        type: "img",
        src: creama,
        style: {
            position: "absolute",
            top: "283px",
            left: "1670px",
        },
    },
    {
        type: "img",
        src: steel,
        style: {
            position: "absolute",
            top: "601px",
            right: "769px",
        },
    },
    {
        type: "img",
        src: steel,
        style: {
            position: "absolute",
            top: "601px",
            right: "497px",
        },
    },
    {
        type: "img",
        src: steel,
        style: {
            position: "absolute",
            top: "601px",
            right: "270px",
        },
    },
    {
        type: "img",
        src: film,
        style: {
            position: "absolute",
            top: "145px",
            left: "1929px",
        },
    },
    {
        type: "img",
        src: freepik,
        style: {
            position: "absolute",
            top: "429px",
            left: "223px",
            zIndex: 10
        },
    },
    {
        type: "img",
        src: groupBack2,
        style: {
            position: "absolute",
            top: "618.5px",
            left: "-2px",
        },
    },
    {
        type: "img",
        src: groupGear,
        style: {
            position: "absolute",
            bottom: "470px",
            left: "2041px",
            zIndex: "-999px",
        },
    },
    {
        type: "img",
        src: groupHouse1,
        style: {
            position: "absolute",
            top: "308px",
            left: "929px",
        },
    },
    {
        type: "img",
        src: groupHome1,
        style: {
            position: "absolute",
            bottom: "32px",
            left: "398px",
        },
    },
    {
        type: "img",
        src: judge,
        style: {
            position: "absolute",
            top: "320px",
            left: "2085px",
        },
    },
    {
        type: "img",
        src: rafiki,
        style: {
            position: "absolute",
            top: "258px",
            left: "192px",
        },
    },
    {
        type: "img",
        src: sheets,
        style: {
            position: "absolute",
            top: "114px",
            left: "1116px",
        },
    },
    {
        type: 'img', // 图片类型
        src: one,
        style: {
            position: "absolute",
            top: "384px",
            left: "146px",
            width: "13px",
            height: "129px",
            transform: "rotateY(180deg)"
        }
    },
    {
        type: 'img', // 图片类型
        src: one,
        style: {
            position: "absolute",
            top: "360px",
            left: "2544px",
            width: "13px",
            height: "129px",
        }
    },

    // Tip
    {
        type: "computerBox",
        text: {
            info: "广告法",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "14px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "296px",
            left: "46px"
        },
    },
    {
        type: "computerBox",
        text: {
            info: "电子商务法",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "14px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "299px",
            left: "1177px"
        },
    },
    {
        type: "computerBox",
        text: {
            info: "网络短视频平台管理规范",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "14px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "110px",
            left: "1723px"
        },
    },
    {
        type: "computerBox",
        text: {
            info: "相关行业及平台规范",
            style: {
                fontSize: "18px",
                fontWeight: "800",
                paddingBottom: "14px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "479px",
            left: "2539px"
        },
    },
    {
        type: "computerBox",
        text: {
            info: "电子商务平台产品信息展示要求",
            style: {
                fontSize: "16px",
                fontWeight: "800",
                paddingBottom: "14px"
            }
        },
        style: {
            backgroundImage: `url(${computerBox})`,
            top: "540px",
            left: "1723px"
        },
    },
    // {
    //     type: "computerBox",
    //     text:{
    //         info: "相关行业及平台规范",
    //         style: {
    //             fontSize: "18px",
    //             fontWeight: "800",
    //             paddingBottom: "14px"
    //         }
    //     },
    //     style: {
    //         backgroundImage: `url(${computerBox})`,
    //         top: "750px",
    //         left: "799px"
    //     },
    // },

    // menuTip
    {
        type: "menuTip",
        id: 1,
        title: "《广告法》与电商直播员",
        sencondTitle: "《广告法》与电商直播员",
        text: "《广告法》与电商直播员",
        sync: "C-1",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "325px",
            top: "378px",
            left: "159px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 2,
        title: "《广告法》中的“素材”的含义与范围",
        sencondTitle: "《广告法》中的“素材”的含义与范围",
        text: "《广告法》中的“素材”的含义与范围",
        sync: "C-2",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "186px",
            top: "517px",
            left: "132px",
            color: "white",
            height: "32px",
            lineHeight: "19px",
            fontSize: "16px",
            width: "213px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 3,
        title: "《广告法》中的引证内容",
        sencondTitle: "《广告法》中的引证内容",
        text: "《广告法》中的引证内容",
        sync: "C-3",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "113px",
            top: "590px",
            left: "132px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px",
            width: "213px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 4,
        title: "《广告法》中的“虚假广告”",
        sencondTitle: "《广告法》中的“虚假广告",
        text: "《广告法》中的“虚假广告”",
        sync: "C-4",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "50px",
            top: "653px",
            left: "132px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px",
            width: "229px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 5,
        title: "典型”违法违规“案例-虚假广告",
        sencondTitle: "典型”违法违规“案例-虚假广告",
        text: "典型”违法违规“案例-虚假广告",
        sync: "C-5",
        src: buttonSearchWhite,
        srca: buttonSearchWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "62px",
            top: "641px",
            left: "551px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 6,
        title: "《广告法》中的违规用语",
        sencondTitle: "《广告法》中的违规用语",
        text: "《广告法》中的违规用语",
        sync: "C-6",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "128px",
            top: "574px",
            left: "606px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 7,
        title: "典型”违法违规“案例-违规用语",
        sencondTitle: "典型”违法违规“案例-违规用语",
        text: "典型”违法违规“案例-违规用语",
        sync: "C-7",
        src: buttonSearchWhite,
        srca: buttonSearchWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "198px",
            top: "504px",
            left: "550px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 8,
        title: "《广告法》的重点法规",
        sencondTitle: "《广告法》的重点法规",
        text: "《广告法》的重点法规",
        sync: "C-8",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "269px",
            top: "433px",
            left: "619px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 9,
        title: "典型”违法违规“案例-违规内容",
        sencondTitle: "典型”违法违规“案例-违规内容",
        text: "典型”违法违规“案例-违规内容",
        sync: "C-9",
        src: buttonSearchWhite,
        srca: buttonSearchWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "338px",
            top: "364px",
            left: "549px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 10,
        title: "《广告法》中的不得出现的情形",
        sencondTitle: "《广告法》中的不得出现的情形",
        text: "《广告法》中的不得出现的情形",
        sync: "C-10",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "411px",
            top: "291px",
            left: "557px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 11,
        title: "典型”违法违规“案例-违规情形",
        sencondTitle: "典型”违法违规“案例-违规情形",
        text: "典型”违法违规“案例-违规情形",
        sync: "C-11",
        src: buttonSearchWhite,
        srca: buttonSearchWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "508px",
            top: "194px",
            left: "881px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 12,
        title: "《电子商务法》与电商直播员",
        sencondTitle: "《电子商务法》与电商直播员",
        text: "《电子商务法》与电商直播员",
        sync: "C-12",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "289px",
            top: "413px",
            left: "944px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 13,
        title: "《电子商务法》中经营者的责任与义务",
        sencondTitle: "《电子商务法》中经营者的责任与义务",
        text: "《电子商务法》中经营者的责任与义务",
        sync: "C-13",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "220px",
            top: "482px",
            left: "965px",
            color: "white",
            height: "32px",
            lineHeight: "19px",
            fontSize: "16px",
            width: "207px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 14,
        title: "《电子商务法》的重点法规",
        sencondTitle: "《电子商务法》的重点法规",
        text: "《电子商务法》的重点法规",
        sync: "C-14",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "150px",
            top: "552px",
            left: "959px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 15,
        title: "典型”违法违规“案例",
        sencondTitle: "典型”违法违规“案例",
        text: "典型”违法违规“案例",
        sync: "C-15",
        src: buttonSearchWhite,
        srca: buttonSearchWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "83px",
            top: "619px",
            left: "990px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 16,
        title: "《治理规定》与电商直播员",
        sencondTitle: "《治理规定》与电商直播员",
        text: "《治理规定》与电商直播员",
        sync: "C-16",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "148px",
            top: "554px",
            left: "1396px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 17,
        title: "《治理规定》中的不良信息",
        sencondTitle: "《治理规定》中的不良信息",
        text: "《治理规定》中的不良信息",
        sync: "C-17",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "217px",
            top: "485px",
            left: "1396px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 18,
        title: "《治理规定》中的禁止情形",
        sencondTitle: "《治理规定》中的禁止情形",
        text: "《治理规定》中的禁止情形",
        sync: "C-18",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "288px",
            top: "414px",
            left: "1396px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 19,
        title: "典型”违法违规“案例",
        sencondTitle: "典型”违法违规“案例",
        text: "典型”违法违规“案例",
        sync: "C-19",
        src: buttonSearchWhite,
        srca: buttonSearchWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "358px",
            top: "344px",
            left: "1396px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 20,
        title: "《治理规定》中的鼓励情形",
        sencondTitle: "《治理规定》中的鼓励情形",
        text: "《治理规定》中的鼓励情形",
        sync: "C-20",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "428px",
            top: "274px",
            left: "1396px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 21,
        title: "典型”正向“案例",
        sencondTitle: "典型”正向“案例",
        text: "典型”正向“案例",
        sync: "C-21",
        src: buttonSearchWhite,
        srca: buttonSearchWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "498px",
            top: "204px",
            left: "1396px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 22,
        title: "《管理规范》与电商直播员",
        sencondTitle: "《管理规范》与电商直播员",
        text: "《管理规范》与电商直播员",
        sync: "C-22",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "455px",
            top: "247px",
            left: "1808px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 23,
        title: "《管理规范》之账户管理规范",
        sencondTitle: "《管理规范》之账户管理规范",
        text: "《管理规范》之账户管理规范",
        sync: "C-23",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "385px",
            top: "317px",
            left: "1808px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 24,
        title: "《管理规范》之内容管理规范",
        sencondTitle: "《管理规范》之内容管理规范",
        text: "《管理规范》之内容管理规范",
        sync: "C-24",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "315px",
            top: "387px",
            left: "1808px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 25,
        title: "《管理规范》的重点规定",
        sencondTitle: "《管理规范》的重点规定",
        text: "《管理规范》的重点规定",
        sync: "C-25",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "245px",
            top: "457px",
            left: "1808px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 26,
        title: "《展示要求》与电商直播员",
        sencondTitle: "《展示要求》与电商直播员",
        text: "《展示要求》与电商直播员",
        sync: "C-26",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "109px",
            top: "593px",
            left: "1838px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 27,
        title: "《展示要求》中的信息内容与要求",
        sencondTitle: "《展示要求》中的信息内容与要求",
        text: "《展示要求》中的信息内容与要求",
        sync: "C-27",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            top: "593px",
            left: "2105px",
            color: "white",
            height: "32px",
            lineHeight: "19px",
            fontSize: "16px",
            width: "197px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 28,
        title: "《展示要求》中的展示方式与要求",
        sencondTitle: "《展示要求》中的展示方式与要求",
        text: "《展示要求》中的展示方式与要求",
        sync: "C-28",
        src: buttonHowWhite,
        srca: buttonHowWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            top: "593px",
            left: "2353px",
            color: "white",
            height: "32px",
            lineHeight: "18px",
            fontSize: "16px",
            width: "183px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 29,
        title: "UGC内容规范",
        sencondTitle: "UGC内容规范",
        text: "UGC内容规范",
        sync: "C-29",
        src: buttonVideoWhite,
        srca: buttonVideoWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "342px",
            top: "360px",
            left: "2392px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 30,
        title: "UGC账号规范",
        sencondTitle: "UGC账号规范",
        text: "UGC账号规范",
        sync: "C-30",
        src: buttonVideoWhite,
        srca: buttonVideoWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "413px",
            top: "289px",
            left: "2411px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 31,
        title: "适用范围与总则",
        sencondTitle: "适用范围与总则",
        text: "适用范围与总则",
        sync: "C-31",
        src: buttonPeaWhite,
        srca: buttonPeaWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "490px",
            top: "212px",
            left: "2399px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 32,
        title: "标题与图片规范",
        sencondTitle: "标题与图片规范",
        text: "标题与图片规范",
        sync: "C-32",
        src: buttonPeaWhite,
        srca: buttonPeaWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "558px",
            top: "144px",
            left: "2400px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 33,
        title: "内容管理规范",
        sencondTitle: "内容管理规范",
        text: "内容管理规范",
        sync: "C-33",
        src: buttonPeaWhite,
        srca: buttonPeaWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            // bottom: "636px",
            top: "66px",
            left: "2416px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },
    {
        type: "menuTip",
        id: 34,
        title: "特殊行业内容规范",
        sencondTitle: "特殊行业内容规范",
        text: "特殊行业内容规范",
        sync: "C-34",
        src: buttonPeaWhite,
        srca: buttonPeaWhite,
        style: {
            background: "#41A1D7",
            position: "absolute",
            top: "66px",
            left: "2209px",
            color: "white",
            height: "32px",
            lineHeight: "30px",
            fontSize: "16px"
        },
        menusList: [],
        menusCurrIndex: 1,
    },

    {
        type: "tip",
        text: "Part4",
        style: {
            position: "absolute",
            top: "204px",
            left: "34px",
            color: "black",
            background: "#FAFF6A",
            paddingLeft: "49px",
            paddingRight: "49px"
        },
    },
    {
        type: 'tipFinger',
        src: finger,
        style: {
            position: "absolute",
            top: "225px",
            left: "140px"
        },
    },
    {
        type: "tip",
        text: "结束学习",
        style: {
            position: "absolute",
            top: "21px",
            left: "2036px",
            color: "black",
            background: "#707070",
        },
    },

    {
        id: "1",
        type: "arrow",
        direction: "Right",
        style: {
            position: "absolute",
            top: "396px",
            right: "2616px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "2",
        type: "arrow",
        direction: "Right",
        style: {
            position: "absolute",
            top: "530px",
            right: "2616px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "3",
        type: "arrow",
        direction: "Right",
        style: {
            position: "absolute",
            top: "602px",
            right: "2616px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "4",
        type: "arrow",
        direction: "Right",
        style: {
            position: "absolute",
            top: "662px",
            right: "2616px",
            background: `url(${blue}) center no-repeat`,
        },
    },

    {
        id: "5",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "654px",
            right: "1861px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "6",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "591px",
            right: "1861px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "7",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "519px",
            right: "1861px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "8",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "449px",
            right: "1861px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "9",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "378px",
            right: "1861px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "10",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "306px",
            right: "1861px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "11",
        type: "arrow",
        direction: "Up",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "250px",
            right: "1675px",
            transform: "rotateZ(270deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },

    {
        id: "12",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "427px",
            right: "1493px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "13",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "496px",
            right: "1493px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "14",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "567px",
            right: "1493px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "15",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "634px",
            right: "1493px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },

    {
        id: "16",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "568px",
            right: "1353px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "17",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "500px",
            right: "1353px",
            background: `url(${arrowRight}) center no-repeat`,
        },
    },
    {
        id: "18",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "429px",
            right: "1353px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "19",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "358px",
            right: "1353px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "21",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "218px",
            right: "1353px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "20",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "288px",
            right: "1353px",
            background: `url(${blue}) center no-repeat`,
        },
    },

    {
        id: "22",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "260px",
            right: "940px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "23",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "329px",
            right: "940px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "24",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "400px",
            right: "940px",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "25",
        type: "arrow",
        direction: "Right",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "471px",
            right: "940px",
            background: `url(${blue}) center no-repeat`,
        },
    },

    {
        id: "26",
        type: "arrow",
        direction: "Up",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "681px",
            right: "762px",
            transform: "rotateZ(270deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "27",
        type: "arrow",
        direction: "Up",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "681px",
            right: "490px",
            transform: "rotateZ(270deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "28",
        type: "arrow",
        direction: "Up",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "681px",
            right: "264px",
            transform: "rotateZ(270deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },

    {
        id: "29",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "373px",
            right: "130px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "30",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "301px",
            right: "130px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "31",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "224px",
            right: "130px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "32",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "154px",
            right: "130px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "33",
        type: "arrow",
        direction: "Left",
        src: arrowRight,
        style: {
            position: "absolute",
            top: "77px",
            right: "130px",
            transform: "rotateY(180deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
    {
        id: "34",
        type: "arrow",
        direction: "Down",
        src: arrowDown,
        style: {
            position: "absolute",
            top: "24px",
            right: "434px",
            transform: "rotateZ(90deg)",
            background: `url(${blue}) center no-repeat`,
        },
    },
];
